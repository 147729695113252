import React, { useMemo, useState } from 'react'

import VideoPlayerLite from 'components/VideoPlayerLite'
import PlayButtonIcon from 'icons/PlayButton'
import { THEME } from 'utils'

const LazyVideoPlayerLite = ({ videoSource, videoOptions = {}, aspectRatio = '16:9', muxTrackingData }) => {
  const [started, setStarted] = useState(false)
  const [width, height] = aspectRatio.split(':').map(Number)
  const paddingTop = `${((height / width) * 100).toFixed(2)}%`
  const poster = useMemo(() => {
    const url = new URL(videoSource.poster)
    url.searchParams.set('width', '400')
    url.searchParams.set('format', 'jpg')
    url.searchParams.set('quality', '50')
    return url.href
  }, [videoSource.poster])

  return (
    <>
      {started ? (
        <VideoPlayerLite
          videoSource={videoSource}
          videoOptions={videoOptions}
          autoplay={true}
          muxTrackingData={muxTrackingData}
        />
      ) : (
        <div className='thumbnail-container'>
          <div className='play-button-container' onClick={() => setStarted(true)}>
            <div className='play-icon-container'>
              <PlayButtonIcon height='26px' width='26px' />
            </div>
          </div>
        </div>
      )}
      <style jsx>{`
        .thumbnail-container {
          position: relative;
          max-width: 100%;
          height: 0;
          padding-top: ${paddingTop};
          background-image: url(${poster});
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .play-button-container {
          position: absolute;
          cursor: pointer;
          height: 90px;
          width: 90px;
          top: calc(50% - 45px);
          left: calc(50% - 45px);
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.45);
        }
        .play-button-container:hover {
          background-color: ${THEME.COLORS.PRIMARY};
        }
        .play-icon-container {
          position: absolute;
          top: calc(50% - 13px);
          left: calc(50% - 11px);
        }
      `}</style>
    </>
  )
}

export default LazyVideoPlayerLite
