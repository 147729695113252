import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import donately from 'lib/donately'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import FundsStore from 'stores/funds'
import StoreStore from 'stores/store'
import { useStore } from 'hooks'
import { THEME, formatWithCommas, cleanProductData } from 'utils'
import StoolAndStarsIcon from 'icons/StoolAndStars'
import Modal from 'components/Modal'
import ProductCarousel from 'components/ProductCarousel'
import VideoPlayerLite from 'components/VideoPlayerLite'
import LazyVideoPlayerLite from 'components/LazyVideoPlayerLite'
import InfiniteScroll from 'components/InfiniteScroll'
import LoadingContainer from 'components/LoadingContainer'
import Arrow from 'icons/Arrow'
import dynamic from 'next/dynamic'

// dynamic import  because typeform lib throws error out when rendering ssr
const TypeformEmbed = dynamic(() => import('components/TypeformEmbed'), { ssr: false })

const BusinessPanel = ({ item, fund, handleClick, featured = false, forceFunded = false }) => {
  const getFundingPercent = function () {
    if (item.status === 'funded' || forceFunded) return 100
    const percent = (item.stats.raised_total / item.stats.raised_goal) * 100
    return percent > 100 ? 100 : percent
  }
  return (
    <>
      <div className='panel'>
        <div className='row'>
          <div className='col col--left'>
            <div className='panel__media'>
              {item.status === 'funded' && item.funded_by.length ? (
                <div className='panel__ribbon'>{item.funded_by[0].funding_summary}</div>
              ) : null}
            </div>
            {item.media.type.startsWith('video') ? (
              <LazyVideoPlayerLite
                videoSource={{
                  id: item.media.id,
                  sources: [
                    {
                      type: item.media.primary_source.type,
                      src: item.media.primary_source.src
                    }
                  ],
                  name: item.name,
                  poster: item.media.thumbnail
                }}
                muxTrackingData={{
                  video_id: item.id,
                  video_variant_id: item.media.id,
                  video_title: item.name,
                  video_series: fund.name,
                  video_stream_type: 'on-demand'
                }}
              />
            ) : (
              <img className='panel__image' src={item.media.url} alt={item.name} />
            )}
          </div>
          <div className='col col--right'>
            <div className='topRow'>
              <div className='progress'>
                <div className='progress__bar'>
                  <div className='progress__progress' style={{ width: `${getFundingPercent()}%` }} />
                </div>
                <div className='progress__stats'>
                  {item.status === 'funded' || forceFunded ? (
                    <span className='funded'>
                      <span>{item.funding_summary || 'FUNDED'}</span>
                      {item.funded_by.length ? (
                        <span className='funded__info'>
                          <span className='funded__sponsor'>{item.funded_by[0].funding_summary}</span>
                          <span
                            className='funded__image'
                            style={{ backgroundImage: `url(${item.funded_by[0].image})` }}
                          />
                        </span>
                      ) : null}
                    </span>
                  ) : (
                    <span className='funded'>
                      <span>
                        <span className='amount'>${formatWithCommas(item.stats.raised_total)}</span>
                        <span className='divider'>/</span>
                        <span className='total'>${formatWithCommas(item.stats.raised_goal)}</span>
                      </span>
                      {item.funded_by.length ? (
                        <span className='funded__info'>
                          <span className='funded__sponsor'>{item.funded_by[0].funding_summary}</span>
                          <span
                            className='funded__image'
                            style={{ backgroundImage: `url(${item.funded_by[0].image})` }}
                          />
                        </span>
                      ) : null}
                    </span>
                  )}
                </div>
              </div>
              {item.funded_by.length ? (
                <div className='sponsor' style={{ backgroundImage: `url(${item.funded_by[0].image})` }} />
              ) : null}
            </div>
            <h2 className='title title--name'>{item.name}</h2>
            <p className='subtitle'>{item.location}</p>
            <div className='description'>
              <p>{item.description}</p>
            </div>
            {featured ? (
              <div className='row row--buttons'>
                {item.store_url && (
                  <div className='col col--button'>
                    <a href={item.store_url} target='_blank' rel='noreferrer' className='btn btn--outline'>
                      Shop Merch
                    </a>
                  </div>
                )}
              </div>
            ) : (
              item.store_url && (
                <a className='merchLink' href={item.store_url} target='_blank' rel='noreferrer'>
                  Shop Merch
                </a>
              )
            )}
          </div>
        </div>
      </div>
      <style jsx>{`
        .merchLink {
          display: block;
          margin: 40px 0;
          color: ${THEME.COLORS.SECONDARY};
          text-decoration: underline;
          font-size: 14px;
          letter-spacing: 1px;
          text-transform: uppercase;
          text-align: center;
        }
        .panel {
          background-color: #fff;
        }
        .panel:first-of-type {
          padding-top: 60px;
          border-top-left-radius: 7px;
          border-top-right-radius: 7px;
        }
        .panel:last-of-type {
          border-bottom-left-radius: 7px;
          border-bottom-right-radius: 7px;
        }
        .panel__media {
          position: relative;
        }
        .panel__ribbon {
          max-width: 120px;
          position: absolute;
          top: 20px;
          left: -8px;
          background-color: #0eb6a1;
          color: #fff;
          padding: 5px 6px;
          font-size: 10px;
          line-height: 11px;
          letter-spacing: 0.83px;
          text-transform: uppercase;
          font-weight: 700;
          z-index: 1;
        }
        .panel__ribbon:before {
          content: '';
          position: absolute;
          display: block;
          border-style: solid;
          border-width: 0 0px 4px 8px;
          border-color: transparent transparent #151e30 transparent;
          left: 0;
          top: -4px;
        }
        .title {
          font-size: 28px !important;
          line-height: 34px !important;
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .panel:first-of-type {
            padding-top: 90px;
          }
          .title {
            font-size: 36px !important;
            line-height: 43px !important;
          }
          .merchLink {
            margin: 40px 0 0;
            text-align: left;
          }
        }
        .progress {
          flex-grow: 1;
          margin: 24px 0;
        }
        .progress__bar {
          width: 100%;
          height: 12px;
          margin-bottom: 8px;
          background-color: #e6e6e6;
          position: relative;
        }
        .progress__progress {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          background-color: #0eb6a1;
        }
        .progress__stats {
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.88px;
          font-weight: 700;
          color: ${THEME.COLORS.SECONDARY};
          text-transform: uppercase;
          text-align: center;
        }
        .progress__stats .amount,
        .progress__stats .funded {
          color: #0eb6a1;
        }
        .progress__stats .total {
          color: ${THEME.COLORS.SECONDARY};
        }
        .progress__stats .funded {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
        }
        .progress__stats .funded__sponsor {
          color: #7f838c;
          font-size: 10px;
          letter-spacing: 0.83px;
          text-transform: uppercase;
        }
        .funded__info {
          display: flex;
          align-items: center;
        }
        .funded__image {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          margin-left: 10px;
        }
        .progress__stats .divider {
          display: inline-block;
          margin: 0 6px;
        }
        .topRow {
          display: flex;
          align-items: flex-start;
        }
        .sponsor {
          display: none;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          margin: -12px 0 0 14px;
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .progress {
            margin: 0 0 24px;
          }
          .progress__stats {
            text-align: left;
          }
          .progress__stats .funded {
            flex-direction: row;
          }
          .funded__image {
            display: none;
          }
          .sponsor {
            display: block;
          }
        }
      `}</style>
    </>
  )
}

const BusinessList = ({ items = [], next = () => {}, hasMore = false, fund, setModalOpen }) => {
  const loader = () => <LoadingContainer isLoading={true} />

  return (
    <InfiniteScroll next={next} hasMore={hasMore} loader={loader}>
      {items.map((item, idx) => (
        <BusinessPanel key={idx} item={item} fund={fund} handleClick={() => setModalOpen(true)} forceFunded />
      ))}
    </InfiniteScroll>
  )
}

const BarstoolFundIndex = observer(({ next, hasMore }) => {
  const fundsStore = useStore(FundsStore)
  const fund = fundsStore.current
  const storeStore = useStore(StoreStore)
  const products = cleanProductData(toJS(storeStore.items))
  const htmlDescription = fund.description.replace(
    'TheBarstoolFund.com/apply',
    '<a href="https://thebarstoolfund.com/apply">TheBarstoolFund.com/apply</a>'
  )
  const featuredItems = fundsStore.items.filter((item) => item.status === 'active')
  const featuredRest = featuredItems.slice(1)
  const fundedItems = fundsStore.items.filter((item) => item.status === 'funded')
  fundedItems.unshift(...featuredRest)
  const [modalOpen, setModalOpen] = useState(false)
  const [stickyHeader, setStickyHeader] = useState(false)
  const [activeForm, setActiveForm] = useState('')
  const handleScroll = function () {
    if (window.scrollY >= 10) {
      setStickyHeader(true)
    } else {
      setStickyHeader(false)
    }
  }
  useEffect(() => {
    async function renderForm() {
      await donately.loadScript()
      window.Donately.init(
        {
          selector: '#donation-form',
          options: toJS(fund.donately_settings),
          afterFormLoad: () => {},
          onSuccess: () => {},
          onError: () => {}
        },
        false
      )
    }
    window.addEventListener('scroll', handleScroll)

    renderForm()
    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  useEffect(() => {
    // when the active form changes to donately, force the round up toggle to be active by default.
    if (activeForm === 'donately') {
      const roundUpToggle = document.querySelector('#donately-donor-pays-fees')
      if (!roundUpToggle?.checked) roundUpToggle?.click()
    }
  }, [activeForm])
  return (
    <>
      <div onScroll={handleScroll} className={`barstoolFund ${stickyHeader ? 'barstoolFund--scrolled' : ''}`}>
        <header className='barstoolFund__header'>
          <div className='container'>
            <Link href='/the-barstool-fund' legacyBehavior>
              <a className='barstoolFund__logo'>
                <img src='/static/images/barstool-fund-logo.png' alt={fund.name} />
              </a>
            </Link>
            <div className='barstoolFund__headerStats'>
              <div className='barstoolFund__headerStat'>
                <span className='value value--green'>${formatWithCommas(fund.stats.raised_total)}</span>
                <span className='label'>Total Funds Raised</span>
                <span className='disclaimer'>(Data Updated Every 5-Minutes)</span>
              </div>
              <div className='barstoolFund__headerStat'>
                <span className='value'>{formatWithCommas(fund.stats.customer_total)}</span>
                <span className='label'>Supporters</span>
              </div>
              <div className='barstoolFund__headerStat'>
                <span className='value'>{formatWithCommas(fund.stats.entity_total)}</span>
                <span className='label'>
                  Businesses <br />
                  Supported
                </span>
              </div>
            </div>
            <div className='barstoolFund__headerCta'>
              <div className='poweredBy'>
                <span>In Support Of</span>
                <img src='/static/images/30dayfund.svg' alt='30 Day Fund' />
              </div>
            </div>
          </div>
        </header>
        <div className='container'>
          <div className='panel barstoolFund__fund'>
            <div className='row'>
              <div className='col col--left'>
                <VideoPlayerLite
                  videoSource={{
                    sources: [
                      {
                        type: fund.media.primary_source.type,
                        src: fund.media.primary_source.src
                      }
                    ],
                    name: fund.name,
                    poster: fund.media.thumbnail
                  }}
                  muxTrackingData={{
                    video_id: fund.id,
                    video_variant_id: fund.media.id,
                    video_title: fund.name,
                    video_series: 'Funds',
                    video_stream_type: 'on-demand'
                  }}
                />
              </div>
              <div className='col col--right'>
                <h1 className='title'>{fund.name}</h1>
                <p className='subtitle'>In support of the 30 Day Fund</p>
                <div className='description'>
                  <p dangerouslySetInnerHTML={{ __html: htmlDescription }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {featuredItems.length ? (
          <div className='container'>
            <div className='barstoolFund__businesses barstoolFund__businesses--featured'>
              <img
                className='badge'
                src='/static/images/featured-business.svg'
                alt='Barstool Fund Featured Businesses'
              />
              {featuredItems.slice(0, 1).map((item) => (
                <BusinessPanel key={item.id} item={item} fund={fund} handleClick={() => setModalOpen(true)} featured />
              ))}
            </div>
          </div>
        ) : null}
        {products.length ? (
          <div className='container'>
            <div className='barstoolFund__products'>
              <ProductCarousel items={products} link='https://store.barstoolsports.com/collections/the-barstool-fund' />
            </div>
          </div>
        ) : null}
        {fund.top_supporters.length ? (
          <div className='container'>
            <div className='barstoolFund__businesses barstoolFund__businesses--supporters'>
              <img className='badge' src='/static/images/top-supporters.svg' alt='Barstool Fund Top Supporters' />
              <div className='panel'>
                <div className='row'>
                  {fund.top_supporters.map((item) => (
                    <div key={item.name} className='col col--supporter'>
                      <div className='barstoolFund__supporter'>
                        <div className='photo' style={{ backgroundImage: `url(${item.image})` }} alt={item.name} />
                        <div className='amount'>{item.funding_summary}</div>
                        <div className='name'>{item.name}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {fundedItems.length ? (
          <div className='container'>
            <div className='barstoolFund__businesses barstoolFund__businesses--funded'>
              <img className='badge' src='/static/images/funded-business.svg' alt='Barstool Fund Funded Businesses' />
              <BusinessList items={fundedItems} next={next} hasMore={hasMore} fund={fund} setModalOpen={setModalOpen} />
            </div>
          </div>
        ) : null}
        <div className='container'>
          <div className='barstoolFund__information'>
            <h2>Does Your Business Need Help?</h2>
            <p>
              Submit your application at <a href='https://thebarstoolfund.com/apply'>TheBarstoolFund.com/apply</a>.
            </p>
          </div>
          <div className='barstoolFund__information'>
            <img src='/static/images/30dayfund.svg' alt='30 Day Fund' />
            <h2>About 30 Day Fund:</h2>
            <p style={{ textAlign: 'left' }}>
              The nonprofit 30 Day Fund was launched by Virginia technology entrepreneur Pete Snyder and his wife,
              Burson. Working with other business leaders throughout the nation, the Fund’s goal is to help save as many
              jobs as possible. Jeff Bartos co-founded the Pennsylvania 30 Day Fund with a group of local business
              leaders and worked with Barstool Sports to help save Reading Terminal Market. Since the first launch in
              Virginia, the 30 Day Fund launched in Pennsylvania, Georgia, Mississippi, Arkansas, and New Jersey. The
              funds disbursed do not need to be repaid, but if businesses who receive the Fund’s assistance do, at a
              later date, wish to “pay it forward,” they may do so by directing those dollars back to the Fund, which
              will disburse them to another business in need. To date, the 30 Day Fund has raised more than $8 million
              and is on the way to funding more than 2,200 small businesses.
            </p>
          </div>
        </div>
        <footer className='barstoolFund__footer'>
          <div className='barstoolFund__footerIcon'>
            <StoolAndStarsIcon width='100%' height='100%' color='#7f838c' />
          </div>
          <p>
            &copy;{new Date().getFullYear()} Barstool Sports - All rights reserved.
            <br />
            <Link href='/terms-of-use' legacyBehavior>
              <a target='_blank'>Terms of Use</a>
            </Link>
            <Link href='/privacy-policy' legacyBehavior>
              <a target='_blank'>Privacy Policy</a>
            </Link>
          </p>
        </footer>
        <Modal isOpen={modalOpen} close={() => setModalOpen(false)} maxWidth='425px' padding='40px 1rem'>
          <div
            className='modal-back-button'
            style={{ display: activeForm ? 'block' : 'none' }}
            onClick={() => setActiveForm('')}
          >
            <Arrow direction='left' width='22px' height='22px' />
          </div>
          <div className='modal-logos'>
            <img src='/static/images/barstool-fund-logo.png' alt={fund.name} />
            <div className='poweredBy'>
              <span className='modal-poweredBy'>In Support Of</span>
              <img src='/static/images/30dayfund.svg' alt='30 Day Fund' />
            </div>
          </div>
          {!activeForm && (
            <>
              <button
                className={`contribute-cta ${activeForm === 'donately' ? 'contribute-cta--selected' : ''}`}
                onClick={() => setActiveForm('donately')}
              >
                Pay With Card / ACH
              </button>
              <button
                className={`contribute-cta ${activeForm === 'crypto' ? 'contribute-cta--selected' : ''}`}
                onClick={() => setActiveForm('crypto')}
              >
                Pay With Crypto
              </button>
              <button
                className={`contribute-cta ${activeForm === 'check' ? 'contribute-cta--selected' : ''}`}
                onClick={() => setActiveForm('check')}
              >
                Pay With Check
              </button>
              <button
                className={`contribute-cta ${activeForm === 'wire' ? 'contribute-cta--selected' : ''}`}
                onClick={() => setActiveForm('wire')}
              >
                Pay With Wire Transfer
              </button>
            </>
          )}
          <div>
            <div style={{ display: activeForm === 'donately' ? 'block' : 'none' }}>
              <div id='donation-form' body-scroll-lock-ignore='true' />
            </div>
            <div
              id='crypto-form'
              style={{ display: activeForm === 'crypto' ? 'block' : 'none' }}
              body-scroll-lock-ignore='true'
            >
              <iframe
                frameBorder='0'
                width='300'
                height='600'
                scrolling='no'
                src='https://tgbwidget.com/?charityID=99006'
              ></iframe>
            </div>
            <div
              id='wire-form'
              style={{ display: activeForm === 'wire' ? 'block' : 'none' }}
              body-scroll-lock-ignore='true'
            >
              <TypeformEmbed id='nAFHgBUH' isActive={activeForm === 'wire'} height='550px' />
            </div>
            <div
              className='contribute-by-check-instructions'
              style={{ display: activeForm === 'check' ? 'block' : 'none' }}
              body-scroll-lock-ignore='true'
            >
              <p>Need to pay by check?</p>
              <p>
                Send your check to:
                <br />
                The Barstool Fund C/O 30 Day Fund
                <br />
                1314 Rugby Road
                <br />
                Charlottesville, VA 22903
              </p>
              <p>
                The Barstool Fund is a campaign by Barstool Sports, Inc. to raise funds for 30 Day Fund, Inc. (EIN:
                85-0602776), a tax-exempt organization qualified under Section 501(c)(3) of the Internal Revenue Code,
                as amended, which was established to save as many jobs as possible during this pandemic by helping small
                businesses meet their payroll, pay their rent/utilities, and/or continue providing health insurance for
                their employees. Your donation to 30 Day Fund is tax-deductible and will go towards a general pool of
                money being raised to help those businesses chosen as part of The Barstool Fund campaign (but will not
                be earmarked for any particular business within that group). Both Barstool and 30 Day Fund will be
                collecting, storing and utilizing any information you provide in connection with your donation pursuant
                to their privacy policies which can be found here: (i) Barstool [
                <a href='https://www.barstoolsports.com/privacy-policy' target='_blank' rel='noreferrer'>
                  https://www.barstoolsports.com/privacy-policy
                </a>
                ]; and (ii) 30 Day Fund [
                <a href='https://va30dayfund.com/privacy-policy/' target='_blank' rel='noreferrer'>
                  https://va30dayfund.com/privacy-policy/
                </a>
                ].
              </p>
            </div>
          </div>
        </Modal>
      </div>
      <style jsx>{`
        .contribute-cta {
          height: 52px;
          max-width: 350px;
          text-transform: uppercase;
          font-weight: bold;
          color: #aaaaaa;
          border: 1px solid #aaaaaa;
          background-color: white;
          padding: 12px;
          margin: 0.5rem auto;
          letter-spacing: 1px;
          font-size: 14px;
          cursor: pointer;
          outline: none;
        }
        .contribute-cta a {
          color: #aaaaaa;
        }
        .contribute-cta--selected {
          background-color: #0eb6a1;
          border: 2px solid #0eb6a1;
          color: white;
          outline: none;
        }
        .contribute-by-check-instructions {
          font-size: 18px;
          max-width: 600px;
          padding: 1rem;
          margin: 2rem auto 0;
          border: 1px solid #dddddd;
          border-radius: 5px;
          box-shadow: 1px 2px 10px 0px #dddddd;
        }
        .modal-logos {
          height: 100px;
          max-width: 280px;
          display: flex;
          margin: -1rem auto 0;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          box-sizing: border-box;
          padding: 0 0 1rem;
        }
        .modal-logos img {
          height: 100%;
          width: auto;
        }
        .modal-logos .poweredBy span {
          letter-spacing: 0.83px;
          margin-right: 0.25rem;
          font-size: 10px;
          color: #8a8e97;
          font-weight: 700;
          text-transform: uppercase;
        }
        .modal-logos .poweredBy {
          display: flex;
          align-items: center;
        }
        .modal-back-button {
          position: absolute;
          top: 0;
          left: 0;
          padding: 1rem;
          cursor: pointer;
        }
        #donation-form iframe {
          width: 390px;
          height: auto;
        }
        #crypto-form iframe {
          width: 100%;
          height: 600px;
        }
        .barstoolFund {
          font-family: Helvetica, -apple-system, Segoe UI, Arial, sans-serif;
          background-color: #f5f5f5;
          padding-top: 515px;
          transition: all 200ms ease-in-out;
        }
        .barstoolFund--scrolled {
          padding-top: 250px;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .barstoolFund {
            padding-top: 204px;
          }
          .barstoolFund--scrolled {
            padding-top: 150px;
          }
        }
        .barstoolFund__header {
          background-color: #fff;
          padding: 30px 0;
          transition: all 200ms ease-in-out;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 2;
        }
        .barstoolFund--scrolled .barstoolFund__header {
          padding: 5px 0;
          box-shadow: rgba(0, 0, 0, 0.1) 0 3px 5px 1px;
        }
        .barstoolFund__header .container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
        }
        .barstoolFund--scrolled .barstoolFund__header .container {
          flex-direction: row;
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .barstoolFund__header {
            padding: 45px 0;
          }
          .barstoolFund__header .container {
            flex-direction: row;
          }
        }
        .barstoolFund__logo {
          display: block;
        }
        .barstoolFund__logo img {
          width: auto;
          height: 60px;
          transition: all 200ms ease-in-out;
        }
        @media (min-width: ${THEME.BREAKPOINTS.LG}px) {
          .barstoolFund__logo img {
            height: 110px;
          }
        }
        .barstoolFund__headerCta .btn {
          width: 100%;
          white-space: nowrap;
        }
        .barstoolFund__headerCta .poweredBy {
          display: flex;
          align-items: center;
          justify-content: center;
          letter-spacing: 0.83px;
          font-size: 10px;
          color: #8a8e97;
          font-weight: 700;
          text-transform: uppercase;
        }
        .barstoolFund__headerCta .poweredBy img {
          display: block;
          height: 30px;
          margin-left: 8px;
        }
        .barstoolFund__headerStats {
          display: flex;
          flex-direction: column;
          margin: 30px 0 10px;
        }
        .barstoolFund__headerStat {
          margin: 0 0 20px;
          text-align: center;
        }
        .barstoolFund__headerStat br {
          display: none;
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .barstoolFund__headerStats {
            flex-direction: row;
            margin: 0;
          }
          .barstoolFund__headerStat {
            margin: 0 0 0 60px;
            text-align: left;
          }
          .barstoolFund__headerStat br {
            display: inline;
          }
        }
        .barstoolFund__headerStat span {
          display: block;
          font-weight: 700;
          letter-spacing: 1px;
        }
        .barstoolFund__headerStat .value {
          font-size: 36px;
        }
        .barstoolFund__headerStat .value--green {
          color: #0eb6a1;
        }
        .barstoolFund__headerStat .label {
          color: ${THEME.COLORS.SECONDARY};
          font-size: 12px;
          text-transform: uppercase;
          margin-top: 6px;
        }
        .barstoolFund__headerStat .disclaimer {
          color: #979797;
          font-size: 12px;
          font-style: italic;
          font-weight: normal;
        }
        @media (min-width: ${THEME.BREAKPOINTS.XL}px) {
          .barstoolFund__headerStat .value {
            font-size: 48px;
          }
          .barstoolFund__headerStat .label {
            font-size: 14px;
          }
        }
        .barstoolFund--scrolled .barstoolFund__logo img {
          height: 55px;
        }
        .barstoolFund--scrolled .barstoolFund__headerStats,
        .barstoolFund--scrolled .barstoolFund__headerCta .poweredBy,
        .barstoolFund--scrolled .barstoolFund__headerCta span {
          display: none;
        }
        .barstoolFund__fund {
          margin-bottom: 80px;
        }
        .barstoolFund__products {
          padding: 0 0 80px 0;
        }
        .barstoolFund__businesses {
          box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 5px 1px;
          border-radius: 7px;
          margin-bottom: 80px;
          position: relative;
        }
        .barstoolFund__businesses .badge {
          position: absolute;
          left: 50%;
          top: 0;
          transform: translate(-50%, -50%);
        }
        .barstoolFund__businesses--supporters .badge,
        .barstoolFund__businesses--featured .badge {
          height: 80px;
        }
        .barstoolFund__businesses--funded .badge {
          height: 70px;
        }
        .barstoolFund__businesses--supporters {
          background-color: #fff;
          padding-top: 60px;
          margin-bottom: 100px;
        }
        .barstoolFund__businesses--supporters .panel {
          overflow: hidden;
          padding-left: 15px;
          padding-right: 15px;
        }
        .barstoolFund__businesses--supporters .row {
          align-items: flex-start;
          overflow-x: auto;
          flex-wrap: nowrap !important;
          margin-left: -15px;
          margin-right: -15px;
        }
        .barstoolFund .col.col--supporter {
          min-width: 130px;
          max-width: 14.285%;
          flex-basis: 14.285%;
          padding-left: 15px;
          padding-right: 15px;
        }
        .barstoolFund__supporter {
          margin-bottom: 15px;
        }
        .barstoolFund__supporter .photo {
          display: block;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          margin: 0 auto 12px auto;
        }
        .barstoolFund__supporter .amount,
        .barstoolFund__supporter .name {
          text-align: center;
          font-size: 14px;
          color: ${THEME.COLORS.SECONDARY};
        }
        .barstoolFund__supporter .amount {
          font-size: 16px;
          font-weight: 700;
          letter-spacing: 1px;
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .barstoolFund__businesses--supporters {
            padding-top: 30px;
          }
          .barstoolFund__businesses--supporters .row {
            flex-wrap: wrap !important;
            justify-content: center;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.LG}px) {
          .barstoolFund__businesses--supporters .panel {
            padding-left: 60px;
            padding-right: 60px;
          }
          .barstoolFund__businesses--supporters .row {
            margin-left: -30px;
            margin-right: -30px;
          }
          .barstoolFund .col.col--supporter {
            min-width: 14.285%;
            max-width: 14.285%;
            flex-basis: 14.285%;
            padding-left: 30px;
            padding-right: 30px;
          }
        }
        .barstoolFund__information {
          padding: 80px 0;
          text-align: center;
        }
        .barstoolFund__information:first-of-type {
          margin-top: -80px;
        }
        .barstoolFund__information + .barstoolFund__information {
          border-top: 1px solid ${THEME.COLORS.SECONDARY};
        }
        .barstoolFund__information h2,
        .barstoolFund__information p {
          color: ${THEME.COLORS.SECONDARY};
          font-size: 14px;
          line-height: 17px;
        }
        .barstoolFund__information h2 {
          letter-spacing: 2px;
          margin-bottom: 18px;
          text-transform: uppercase;
        }
        .barstoolFund__information img {
          display: block;
          height: 50px;
          margin: 0 auto 18px;
        }
        .barstoolFund__information p {
          max-width: 800px;
          margin: auto;
        }
        .barstoolFund__information p a {
          color: #ba1930;
          font-weight: 700;
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .barstoolFund__information h2,
          .barstoolFund__information p {
            font-size: 20px;
            line-height: 24px;
          }
        }
        .barstoolFund__footer {
          background-color: ${THEME.COLORS.SECONDARY};
          text-align: center;
          padding: 30px 0;
          margin-bottom: -20px;
          color: #7f838c;
        }
        .barstoolFund__footerIcon {
          margin: 0 auto 18px;
          width: 60px;
          height: 60px;
        }
        .barstoolFund__footer p {
          font-size: 14px;
          line-height: 28px;
        }
        .barstoolFund__footer p a {
          margin: 0 8px;
          color: #7f838c;
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .barstoolFund__footer p {
            font-size: 16px;
            line-height: 24px;
          }
        }
      `}</style>
    </>
  )
})

export default BarstoolFundIndex
