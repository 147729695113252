import React from 'react'
import dynamic from 'next/dynamic'
import { useMediaQuery } from 'react-responsive'
import { THEME } from 'utils'
import ArrowIcon from 'icons/Arrow'
import StoreProduct from 'components/StoreProduct'
const Slider = dynamic(() => import('react-slick'), { ssr: false })

const NextArrow = ({ className, style, onClick }) => (
  <div className={className} style={style} onClick={onClick}>
    <ArrowIcon direction='right' width='auto' height='24px' color={THEME.COLORS.SECONDARY} />
  </div>
)
const PrevArrow = ({ className, style, onClick }) => (
  <div className={className} style={style} onClick={onClick}>
    <ArrowIcon direction='left' width='auto' height='24px' color={THEME.COLORS.SECONDARY} />
  </div>
)
const ProductCarousel = ({ items, link }) => {
  const shouldSlick = useMediaQuery({ query: '(min-width: 768px)' })
  const settings = {
    className: 'productCarousel__slider',
    dots: false,
    infinite: false,
    lazyLoad: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToShow: 4,
    slidesToScroll: 4
  }
  if (!items || !items.length) return null
  const slides = items.slice(0, 15).map((item) => {
    return (
      <div key={item.id} className='col col--item'>
        <StoreProduct product={item} />
        <style jsx>{`
          .col--item {
            flex-basis: 220px;
            min-width: 220px;
            max-width: 220px;
            padding-left: 15px !important;
            padding-right: 15px !important;
          }
        `}</style>
      </div>
    )
  })
  return (
    <>
      <div className='productCarousel'>
        {link && (
          <div className='productCarousel__link'>
            <a href={link} target='_blank' rel='noreferrer'>
              View all
            </a>
          </div>
        )}
        <div className='productCarousel__body'>
          {shouldSlick ? <Slider {...settings}>{slides}</Slider> : <div className='row row--scroll'>{slides}</div>}
        </div>
      </div>
      <style jsx global>{`
        .productCarousel__slider {
          padding-bottom: 10px;
        }
        .productCarousel__slider.regular {
          display: flex;
          flex-wrap: nowrap;
          overflow-x: auto;
          justify-content: left;
          padding-bottom: 0;
          margin-left: -8px;
          margin-right: -8px;
        }
        .productCarousel .slick-arrow {
          top: 40%;
        }
        .productCarousel .slick-arrow.slick-disabled {
          opacity: 0;
        }
        .productCarousel .productCarousel__dots {
          margin: 0;
          padding: 0;
          text-align: right;
          bottom: 0px;
          right: 15px;
        }
        .productCarousel .productCarousel__dots li {
          width: 5px;
          height: 5px;
          margin: 0 5px;
        }
        .productCarousel .productCarousel__dots li button {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          margin: 0;
          padding: 0;
          background: #d8d8d8;
        }
        .productCarousel .productCarousel__dots li.slick-active button {
          background: ${THEME.COLORS.SECONDARY};
        }
        .productCarousel .productCarousel__dots li button:before {
          content: none;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .productCarousel__slider .col--item {
            flex-basis: 100% !important;
            min-width: 100% !important;
            max-width: 100% !important;
          }
        }
      `}</style>
      <style jsx>{`
        .productCarousel + .productCarousel {
          margin-top: 16px;
        }
        .row {
          margin-left: -8px;
          margin-right: -8px;
        }
        .col--item {
          flex-basis: 220px;
          min-width: 220px;
          max-width: 220px;
        }
        .productCarousel__link {
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }
        .productCarousel__link a {
          font-size: 14px;
          line-height: 24px;
          color: ${THEME.COLORS.SECONDARY};
          text-transform: uppercase;
          text-decoration: underline;
        }
        .productCarousel__body {
          padding-top: 12px;
          padding-bottom: 12px;
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          .productCarousel__body {
            padding-left: 0;
            padding-right: 0;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .row {
            margin-left: -15px;
            margin-right: -15px;
          }
          .col--item {
            flex-basis: 100%;
            min-width: 100%;
            max-width: 100%;
          }
          .productCarousel__body {
            margin-left: -15px;
            margin-right: -15px;
          }
        }
      `}</style>
    </>
  )
}

export default ProductCarousel
