import { observable, flow, toJS } from 'mobx'
import { CommonStore } from 'stores/common'
import { fundsApi } from 'api'

export const FundsStore = () => {
  const findEntitiesById = flow(function* (id, params) {
    const response = yield this.api.findEntitiesById(id, params)
    this.items = response
    return response
  })

  const findNextEntitiesById = flow(function* (id, params) {
    try {
      this.isLoading = true
      const items = yield this.api.findEntitiesById(id, params)
      this.items.push(...items)
      return toJS(this.items)
    } finally {
      this.isLoading = false
    }
  })

  return observable({
    ...CommonStore,
    api: fundsApi,
    findEntitiesById,
    findNextEntitiesById
  })
}

FundsStore.cacheKey = 'FundsStore'

export default FundsStore
