import React from 'react'
import Head from 'components/Head'
import { FundsStore } from 'stores/funds'
import { StoreStore } from 'stores/store'
import { getServerSideWrapper } from 'utils'
import { usePageView, useStore, usePagination } from 'hooks'

import BarstoolFundIndex from 'components/BarstoolFundIndex'
import GlobalStyles from 'components/GlobalStyles'

const BarstoolFundIndexPage = ({ current, items, products }) => {
  usePageView()
  useStore(StoreStore, { items: products })

  const fundsStore = useStore(FundsStore, { current, items })

  const pagination = usePagination(fundsStore.items, (query) =>
    fundsStore.findNextEntitiesById(current.id, {
      limit: query.limit,
      offset: query.limit * (query.page - 1),
      populate: 'media(id,title,url,type,thumbnail,primary_source)'
    })
  )

  return (
    <>
      <Head
        title='The Barstool Fund | Barstool Sports'
        canonical={`${process.env.SITE_URL}/the-barstool-fund`}
        description='Support local businesses that need help because of COVID'
        ogImage='https://chumley.barstoolsports.com/union/2020/12/23/barstool-fund-ogimage.ff4fc6ca.png'
      />
      <BarstoolFundIndex {...pagination} />
      <GlobalStyles />
    </>
  )
}

export const getServerSideProps = getServerSideWrapper(
  async () => {
    const [fund, entities] = await Promise.all([
      FundsStore().findById(process.env.BARSTOOL_FUND_ID, { populate: 'media(primary_source,thumbnail)' }),
      FundsStore().findEntitiesById(process.env.BARSTOOL_FUND_ID, {
        populate: 'media(id,title,url,type,thumbnail,primary_source)',
        limit: 25
      })
    ])
    const products = await StoreStore().findProducts({ tag: 'barstoolfundpagemodule' })
    return { current: fund, items: entities, products }
  },
  { maxAge: 10 }
)

export default BarstoolFundIndexPage
